import { SUBSCRIPTION } from '@/constants';
import { mockConsent } from '@/__mocks__';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAdding: (state) => state.isAdding,
  agreements: (state) => state.agreements,
  hasAgreementsToSubscribe: (state) => state.agreements.length > 0,
  identity: (state) => state.identity,
  hasIdentity: (state) => state.identity !== null,
  onBoarding: (state) =>
    state.identity && state.identity.onboarding
      ? state.identity.onboarding
      : null,
  referral: (state) =>
    state.identity && state.identity.referral ? state.identity.referral : '',
  subscriptionStatus: (state) =>
    state.identity && state.identity.subscription_status
      ? state.identity.subscription_status
      : SUBSCRIPTION.STATUS.NONE,
  subscription: (state) =>
    state.identity?.subscription ? state.identity.subscription : undefined,
  wallet: (state) => state.identity?.wallet,
  isAdmin: () => true,
  yapilyConsent: () => mockConsent(3),
};
