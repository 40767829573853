export const labels = {
  acceptCredential: 'Accetta le credenziali',
  address: 'Indirizzo',
  all: 'Tutte',
  amount: 'Importo',
  authorizeDate: 'Data autorizzazione',
  availables: 'Disponibili',
  back: 'Indietro',
  buy: 'Acquista',
  buyCredits: 'Acquista crediti',
  buyNow: 'Acquista adesso',
  cancel: 'Annulla',
  cardNumber: 'Numero carta',
  clear: 'Pulisci',
  code: 'Codice',
  codeCvc: 'Codice cvc',
  comingNext: 'Prossimamente',
  companyName: 'Nome azienda',
  companyVat: 'Partita iva azienda',
  confirm: 'Conferma',
  confirmPayment: 'Conferma il pagamento',
  consent: 'Consenso',
  contentUri: 'Content URI',
  copy: 'Copia',
  country: 'Nazione',
  creditPrice: 'Prezzo del credito',
  creditsAvailable: 'Crediti disponibili',
  creditsSendAmount:'Quantità di crediti da inviare',
  creditsSendToAddress: 'Invia crediti ad un indirizzo valido',
  delete: 'Cancella',
  deposit: 'Deposita',
  depositCredits: 'Deposita crediti',
  description: 'Descrizione',
  details: 'Dettagli',
  didCom: 'did:com:',
  edit: 'Modifica',
  email: 'Email',
  expire: 'Scade',
  expiryDate: 'Data scadenza',
  fileDescription: 'Descrizione del file',
  fileName: 'Nome del file',
  fileUpload: 'Carica file',
  half: 'Metà',
  holderName: 'Nome titolare',
  inProgress: 'In corso',
  institutionName: 'Nome istituzione',
  invoicingEmail: 'Email fatturazione',
  iUnderstand: 'Ho capito',
  legalRepresentativeCode: 'Codice fiscale legale rappresentante',
  legalRepresentativeEmail: 'Email legale rappresentante',
  legalRepresentativeFirstName: 'Nome legale rappresentante',
  legalRepresentativeLastName: 'Cognome legale rappresentante',
  legalRepresentativeMobile: 'Cellulare legale rappresentante',
  makeBankTransfer: 'Effettua bonifico bancario',
  max: 'Max',
  membershipType: 'Tipo di membership',
  min:'Min',
  more: 'Di più',
  name: 'Nome',
  new:'Nuovo',
  newBalance: 'Nuovo bilancio',
  notAvailable: 'Non disponibile',
  notVerified: 'Non verificato',
  open: 'Apri',
  options: 'Opzioni',
  paidAmount: 'Importo pagato',
  paidAt: 'Pagato il',
  pay: 'Paga',
  paymentId: 'ID pagamento',
  processError: 'Errore di processo',
  processId: 'ID processo',
  productQuantity: 'Quantità prodotto',
  quantity: 'Quantità',
  received: 'Ricevuto',
  reloadApp: 'Ricarica l\'app',
  removeConsent: 'Rimuovi consenso',
  retryPayment: 'Riprova il pagamento',
  save: 'Salva',
  scanTheAddress: 'Scansiona l\'indirizzo',
  schemaUri: 'Schema URI',
  schemaVersion: 'Versione schema',
  sdiCode: 'Codice SDI',
  search: 'Cerca',
  select: 'Seleziona',
  send: 'Invia',
  sendCredits: 'Invia crediti',
  sent: 'Inviato',
  share: 'Condividi',
  signOut: 'Esci',
  status: 'Stato',
  swap: 'Swap',
  swapFee: 'Commissione di swap',
  tokenCost: 'Costo CCC (Commercio Cash Credit)',
  total: 'Totale',
  totalAmount: 'Importo totale',
  transactionFee: 'Commissione di transazione',
  transferFee: 'Commissione',
  unitProcessCost: 'Unit process cost',
  vat: 'IVA',
  vatAmount: 'Importo IVA',
  verified: 'Verificato',
  version: 'Versione',
  view: 'Visualizza',
  wallet: 'Wallet',
  yes: 'Si',
  youWillGet: 'Riceverai',
  youWillPay: 'Pagherai',
  yourBalance: 'Il tuo bilancio',
  yourBankAccountUsername:'Il tuo nome utente del conto bancario',
};
